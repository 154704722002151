import {
  EmptyState,
  IllustrationMdEmptyBox,
  ResponsiveSection,
} from '@stitch-fix/mode-react';
import { GetStaticProps, NextPage } from 'next';
import Head from 'next/head';
import { Layout } from '../../components/Layout';

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  // we only want to show this page to UK visitors, so return a 404 for US visitors
  if (locale === 'en-US') {
    return {
      notFound: true,
    };
  }

  // we're using getStaticProps so we can return a 404 for US visitors
  // but we don't need any props since the page is hardcoded
  return {
    props: {},
  };
};

const ThankYouPage: NextPage = () => {
  return (
    <>
      <Head>
        <title>Stitch Fix UK | Stylist | Clothing Stylists</title>
      </Head>
      <Layout>
        <ResponsiveSection
          as="main"
          // setting this id allows the "Skip to page content" link to render from knit
          id="knit-main-content"
          mt="0"
          width={{ sm: 'full-bleed' }}
          gutter="none"
        >
          <EmptyState
            as="section"
            heading="Our UK journey has come to an end"
            image={<IllustrationMdEmptyBox width={240} />}
            primaryAction={{
              as: 'a',
              href: 'https://support.stitchfix.co.uk/hc/en-gb/',
              children: 'View FAQs',
            }}
            mx="auto"
            my={{ sm: 2, lg: 3.5 }}
          >
            Thank you for shopping with us over the years.
          </EmptyState>
        </ResponsiveSection>
      </Layout>
    </>
  );
};

export default ThankYouPage;
