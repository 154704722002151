import React, { ReactNode } from 'react';
import { SizeValue } from '../_internal/styling';

// Props passed to the svg processing.
export interface IllustrationProps
  extends Omit<React.SVGProps<SVGSVGElement>, 'children'> {
  /**
   * Sets the width of the container. Height will be scaled automatically to
   * keep the illustration proportions.
   *
   * Please note design warnings about scaling beyond 20% larger or smaller than
   * the original image size.
   *
   * @default '100%'
   */
  width?: SizeValue;
}

export interface IllustrationBaseProps extends IllustrationProps {
  /**
   * Used internally to wrap svgs when generating illustrations. `children`
   * should not be used on illustration components.
   */
  children: (props: IllustrationProps) => ReactNode;
}

/**
 * This component is used as a wrapper for all illustrations to provide a
 * consistent way to apply props, check for accessiblity, etc. This component
 * should not be used directly, but is listed in storybook to provide general
 * documentation for working with illustrations.
 *
 * See
 * [illustrations](https://mode-react.daylight.stitchfix.com/?path=/docs/illustrations--page)
 * for a full list of available icons.
 */
const IllustrationBase = ({
  children,
  width = '100%',
  ...rootProps
}: IllustrationBaseProps) => {
  return (
    <>
      {children({
        ...rootProps,
        width,
        height: '100%',
        'aria-hidden': true,
      })}
    </>
  );
};

export default IllustrationBase;
